'use strict';

(function() {
  class PrintFindingsCtrl {
    constructor($scope, $rootScope, Cart, usSpinnerService, Finding, Auth, Util, toastr) {
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.Cart = Cart;
      this.Auth = Auth;
      this.toastr = toastr;
      this.site = $scope.site;
      this.usSpinnerService = usSpinnerService;
      this.Finding = Finding;
      this.allFindings = [];
      this.findings = [];
      this.selectedCategory = 'all';
      this.getClass = Util.getFindingClass;

      this.startSpin('spinner-1');
      Auth.getCurrentUser((user)=> {
        if (user && user.organization) {
          this.organizationId = user.organization._id;
          this.fetchFindings();
        } else {
          $state.go('login');
        }
      });
    }

    filterResults() {
      if (this.selectedCategory != 'all') {
        this.findings = this.allFindings.filter(finding=>finding.category == this.selectedCategory);
      } else {
        this.findings = this.allFindings.slice();
      }
    }

    fetchFindings() {
      this.loading = true;
      let qry = {controller: this.organizationId};
      if (this.site) {
        qry.page = this.site._id;
      }
      this.Finding.getFindingsForPrint(qry).$promise
        .then(response=> {
          if (response.data) {
            this.allFindings = response.data.slice();
            this.findings = response.data;
            // this.$scope.components = PrintFindingsCtrl.getBladesGroup(this.allFindings);
          }
          this.loading = false;
          // this.stopSpin('spinner-1');
        })
        .catch(err=> {
          console.log(err);
          // this.stopSpin('spinner-1');
          this.loading = false;
        })
    }

    static getBladesGroup(allFindings) {
      let findings = allFindings.sort((a, b) => a.blade.position - b.blade.position);
      let allBlades = [];
      let blades = {};
      for (let i = 0; i < findings.length; ++i) {
        let obj = findings[i];
        if (blades[obj.blade._id] === undefined)
          blades[obj.blade._id] = [];
        blades[obj.blade._id].push(obj);
      }
      for (let key in blades) {
        allBlades.push({findings: blades[key], blade: blades[key][0].blade});
      }
      return allBlades;
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('PrintFindingsCtrl', PrintFindingsCtrl);
})();
